import React from "react";
import Header from "../components/header";
import QuotesLocal from "../components/quoteslocal";

export default function Home() {
  console.log(
    "%c [^_^] Thanks for taking a Peek! My interests are in ML (NLP), Privacy and Anti Abuse Tech. If you have a question or just want to chat, hit me up on twitter!",
    "background: #222; color: #bada55; font-size: 2em; font-weight: bolder; text-shadow: #000 1px 1px;"
  );
  return (
    <div>
      <Header></Header>
      <hr className="dotted"></hr>
      <QuotesLocal></QuotesLocal>
    </div>
  );
}
