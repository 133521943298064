import React from "react";

export default function Header() {
  return (
    <div>
      <img type="image/png" src={"/logo.png"} alt="logo"></img>
      <div className="nav-grid-wrap">
        <h1>Ace Kulshrestha</h1>

        <nav className="main-nav">
          <ul>
            <li>
              <a
                rel="noreferrer"
                href="https://www.linkedin.com/in/achinkul/"
                target="_blank"
              >
                Linkedin
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="https://twitter.com/ackul_"
                target="_blank"
              >
                Twitter
              </a>
            </li>
            <li>
              <a
                rel="noreferrer"
                href="https://github.com/ackul"
                target="_blank"
              >
                Github
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}
