import React, { useState, useEffect } from "react"
import QuotesLocalData from "../../content/quotes.json"

const QuotesLocal = () => {
  // Client-side Runtime Data Fetching
  const [quoteString, setQuoteString] = useState("")
  useEffect(() => {
    const randIndex = Math.floor(Math.random() * QuotesLocalData.length)
    setQuoteString(QuotesLocalData[randIndex].text + ' - ' + QuotesLocalData[randIndex].from);
  }, []);

  return (
    <section>
      <p>{quoteString}</p>
    </section>
  )
}
export default QuotesLocal